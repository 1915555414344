import React, { useState, useEffect, useRef } from 'react';

const Counter = ({ target }) => {
  const [count, setCount] = useState(0);
  const counterRef = useRef(null);

  // Let's assume we want all counters to take exactly 3 seconds to finish.
  const duration = 5000; // duration in milliseconds
  const frameRate = 1000 / 60; // 60 fps
  const framesNeeded = duration / frameRate;
  const increment = target / framesNeeded; // this ensures a smooth increment

  const isInViewport = (element) => {
    const rect = element.getBoundingClientRect();
    return rect.top >= 0 && rect.bottom <= window.innerHeight;
  };

  useEffect(() => {
    const onScroll = () => {
      // Trigger the counting when the counter is visible
      if (isInViewport(counterRef.current) && count === 0) {
        const interval = setInterval(() => {
          setCount((prevCount) => {
            const newCount = prevCount + increment;
            return newCount < target ? newCount : target;
          });
        }, frameRate);

        // Clear interval on component unmount or when target is reached
        return () => clearInterval(interval);
      }
    };

    // Add scroll event listener
    window.addEventListener('scroll', onScroll);
    onScroll(); // trigger a scroll check on mount in case the counter is already in view

    // Clean up
    return () => window.removeEventListener('scroll', onScroll);
  }, [target]);

  // Round the count for display to avoid decimal places
  return <div ref={counterRef}>{Math.ceil(count)}</div>;
};

export default Counter;
