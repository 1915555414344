import './App.css'

import Header from './page/layout/Header'
import Footer from './page/layout/Footer'
import ScrollingComponent from './component/scrolling'

import Home from './page/Home'

function App () {
  return (
    <div className='App'>
      <Header />
      <Home />
      <Footer />
      <ScrollingComponent />
    </div>
  )
}

export default App
