import React from 'react'
import { BsTwitterX, BsFillEnvelopeFill } from 'react-icons/bs'

export default function Footer () {

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <footer className='w-full bg-[#0e1111] py-4 border-t-[1px] border-t-gray-700'>
      <div className='container mx-auto flex flex-col items-center justify-center gap-3'>
        {/* Social Icons */}
        <div className='flex flex-row items-center justify-center gap-6'>
          <a
            href='https://twitter.com/purechaincap'
            target="_blank"
            rel="noopener noreferrer"
            className='text-[#56CCF2] hover:text-blue-100'
            aria-label='Twitter'
          >
            <BsTwitterX size={30} />
          </a>
          <a
            href='mailto:team@purechaincapital.com'
            target="_blank"
            rel="noopener noreferrer"
            className='text-[#56CCF2] hover:text-blue-100'
            aria-label='Email'
          >
            <BsFillEnvelopeFill size={34} />
          </a>
        </div>

        {/* Links */}
        <div className='flex flex-row items-center justify-center gap-6 text-sm text-gray-300'>
          <a
            href='/privacy-policy'
            className='hover:text-gray-200 transition-colors duration-200'
          >
            Privacy Policy
          </a>
          <a
            href='/terms-of-use'
            className='hover:text-gray-200 transition-colors duration-200'
          >
            Terms of Use
          </a>
        </div>

        {/* Copyright */}
        <div className='text-gray-400 text-xs mt-3'>
          © 2023 Purechain Capital. All rights reserved.
        </div>
        <button
          onClick={scrollToTop}
          className="scrollToTopButton text-white hover:text-[#56CCF2]"
          aria-label="Back to top"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-up">
            <polyline points="18 15 12 9 6 15"></polyline>
          </svg>
        </button>
      </div>
    </footer>
  )
}
