import { useState, useEffect } from 'react';
import '../App.css'; // Ensure CSS is correctly imported

function ScrollingComponent() {
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (!isScrolling) {
        setIsScrolling(true);
      }
      clearTimeout(window.scrollTimeout);
      window.scrollTimeout = setTimeout(() => {
        setIsScrolling(false);
      }, 500);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(window.scrollTimeout);
    };
  }, [isScrolling]);

  useEffect(() => {
    if (isScrolling) {
      document.body.classList.add('show-scrollbar');
    } else {
      document.body.classList.remove('show-scrollbar');
    }
  }, [isScrolling]);
}

export default ScrollingComponent;
